import { createContext, useState } from "react";

// uttils
import { LocalStorageMethods } from "../utils/LocalStorageMethods";

//consts
import { NAMES } from '../consts/namesLocalStorage';

export const AuthContext = createContext({} as any);


type Props = { children: string | JSX.Element | JSX.Element[] }

export const HookUseContext = ({ children }: Props) => {
    const [authValue, setAuthValue] = useState<any>(LocalStorageMethods.getLocalStorage(NAMES.INFO_TOKEN));

    return (
        <AuthContext.Provider value={{ authValue, setAuthValue }}>
            {children}
        </AuthContext.Provider>
    )
}