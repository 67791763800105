// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarouselComponent_swiperSlide__eKLZ7 {
    cursor: pointer;
    padding: 14px;
    background-color: #f0f0f0;
    text-align: center;
    max-width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 14px;
        font-weight: 600;
    }

    p {
        font-size: 12px;
    }
}

.CarouselComponent_swiperSlideSelected__lWDEV {
    cursor: pointer;
    border: 2px solid #006930;
    padding: 14px;
    background-color: #f0f0f0;
    text-align: center;
    max-width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 14px;
        font-weight: 600;
    }

    p {
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/CarouselComponent.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".swiperSlide {\n    cursor: pointer;\n    padding: 14px;\n    background-color: #f0f0f0;\n    text-align: center;\n    max-width: 172px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    h3 {\n        font-size: 14px;\n        font-weight: 600;\n    }\n\n    p {\n        font-size: 12px;\n    }\n}\n\n.swiperSlideSelected {\n    cursor: pointer;\n    border: 2px solid #006930;\n    padding: 14px;\n    background-color: #f0f0f0;\n    text-align: center;\n    max-width: 172px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    h3 {\n        font-size: 14px;\n        font-weight: 600;\n    }\n\n    p {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiperSlide": `CarouselComponent_swiperSlide__eKLZ7`,
	"swiperSlideSelected": `CarouselComponent_swiperSlideSelected__lWDEV`
};
export default ___CSS_LOADER_EXPORT___;
