import { createContext, useState } from "react";

export const ProfileInfoContext = createContext({} as any);

type Props = { children: string | JSX.Element | JSX.Element[] }

export const ProfileUseContext = ({ children }: Props) => {
    const [profileValue, setProfileValue] = useState<any>();

    if (profileValue && profileValue.name)
        profileValue.name = profileValue?.name?.trim();
    
    return (
        <ProfileInfoContext.Provider value={{ profileValue, setProfileValue }}>
            {children}
        </ProfileInfoContext.Provider>
    )
}