// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contract_container__nErhy,
.Contract_loadingRequest__Wio03 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3em;

}

.Contract_container__nErhy .Contract_subContainer__d3nhu {
    max-width: 1300px;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    margin-top: 5em;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu .Contract_return__GJ4Q6 {
    width: 15%;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5em;
    font-size: 14px;
    font-weight: 400;
    color: #727588;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu .Contract_title__bMBsC {
    margin-top: 1em;
    color: #006930;
    font-weight: 750;
}`, "",{"version":3,"sources":["webpack://./src/pages/contract/Contract.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;;AAEf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".container,\n.loadingRequest {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: 3em;\n\n}\n\n.container .subContainer {\n    max-width: 1300px;\n    width: 100%;\n    padding: 1em;\n    display: flex;\n    flex-direction: column;\n    min-height: 50vh;\n    margin-top: 5em;\n}\n\n.container .subContainer .return {\n    width: 15%;\n}\n\n.container .subContainer a {\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n    gap: 0.5em;\n    font-size: 14px;\n    font-weight: 400;\n    color: #727588;\n}\n\n.container .subContainer .title {\n    margin-top: 1em;\n    color: #006930;\n    font-weight: 750;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Contract_container__nErhy`,
	"loadingRequest": `Contract_loadingRequest__Wio03`,
	"subContainer": `Contract_subContainer__d3nhu`,
	"return": `Contract_return__GJ4Q6`,
	"title": `Contract_title__bMBsC`
};
export default ___CSS_LOADER_EXPORT___;
