//exception
import { ApiException } from "../../api/ApiException";

//axios
import appAxios from "../../api/appAxios";

//interfaces
import { IContract } from "../../interfaces/IContract";
import { IContractSimplified } from "../../interfaces/IContractSimplified";
import { IContractFiles } from "../../interfaces/IContractFiles";
import { IGenericFileInfo } from "../../interfaces/IIncomeReport";
import { IPage } from "../../interfaces/IPage";

const getAllContracts = async (): Promise<IContract[] | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_CONTRACTS_INFORMATION}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};
const getAllInstallments = async (
  {
    contractCode,
    originCode,
    dueDate,
    status,
    page,
    size
  }: any): Promise<IPage | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_CONTRACTS_INSTALLMENTS
      ?.replace("{contractCode}", contractCode)
      ?.replace("{originCode}", originCode)}`, {
      params: {
        dueDate,
        status,
        page,
        size
      }
    });
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getContract = async (
  {
    contractCode,
    originCode,
  }: any): Promise<any | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_CONTRACT_INSTALLMENT
      ?.replace("{contractCode}", contractCode)
      ?.replace("{originCode}", originCode)}`, {
    });
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getDownloadContract = async (contractCode: string): Promise<IGenericFileInfo | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API_CONTRACT_DOCUMENT_DOWNLOAD
        ?.replace("{contractCode}", contractCode)}`);
    if (response.status === 200 && response?.data === '') return new ApiException("Não foi possível processar o contrato. Por favor, entre em contato com a nossa Central de Atendimento para assistência.", true);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const getDownloadProposal = async (proposalCode: string, documentTypeCode: string): Promise<IGenericFileInfo | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_DOCUMENT_DOWNLOAD
        ?.replace("{proposalCode}", proposalCode).replace("{documentTypeCode}", documentTypeCode)}`);
    if (response.status === 200 && response?.data === '') return new ApiException("Não foi possível processar o contrato. Por favor, entre em contato com a nossa Central de Atendimento para assistência.", true);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const sendEmailContract = async (contractCode: string,): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API_CONTRACT_DOCUMENT_SEND_EMAIL
        ?.replace("{contractCode}", contractCode)}`);
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const sendEmailProposal = async (proposalCode: string, documentTypeCode: string): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_DOCUMENT_SEND_EMAIL
        ?.replace("{proposalCode}", proposalCode).replace("{documentTypeCode}", documentTypeCode)}`);
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const getContractSimplified = async (cpfCnpj: string, contractCode: string, originCode: string): Promise<IContractSimplified | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API_CONTRACT_SIMPLIFIED_INFORMATION
        ?.replace("{cpfCnpj}", cpfCnpj)
        ?.replace("{contractCode}", contractCode)
        ?.replace("{originCode}", originCode)
        }`
      );
    if (response.status === 200 && response?.data === '') return new ApiException("Não tem contrato");
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const getContractFiles = async (cpfCnpj: string, contractCode: string): Promise<IContractFiles[] | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API_CONTRACT_FILES
        ?.replace("{cpfCnpj}", cpfCnpj)
        ?.replace("{contractCode}", contractCode)
        }`
      );
    if (response.status === 200 && response?.data.length === 0) return new ApiException("Documentos não encontrados");
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

export const ContractService = {
  getAllContracts,
  getAllInstallments,
  getContract,
  getDownloadContract,
  getDownloadProposal,
  sendEmailContract,
  sendEmailProposal,
  getContractSimplified,
  getContractFiles
};
