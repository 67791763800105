import axios from "axios";

// interfaces
import { IInfoToken } from "../interfaces/IInfoToken";

// utils
import { LocalStorageMethods } from "../utils/LocalStorageMethods";

//consts
import { NAMES } from "../consts/namesLocalStorage";

//services
import { LoginService } from "../services/login/LoginService";

//exception
import { ApiException } from "./ApiException";


// Variavel para informar se está acontecendo uma requisição de refresh token
let isRefreshing = false;
// Variavel para armazenar a fila de requisições que falharam por token expirado
let failedRequestQueue: any = [];

const extractDecode = (): string | null => {
  let decode = LocalStorageMethods.getLocalStorage(NAMES.INFO_TOKEN);
  if (!decode) decode = LocalStorageMethods.getLocalStorage(NAMES.INFO_TOKEN_REGISTER);

  return decode;
}

const throwErrorAndClearLocalStorage = (error: any) => {
  LocalStorageMethods.removeLocalStorage(NAMES.INFO_TOKEN);
  window?.location?.reload();
  //return Promise.reject(error);
}

const appAxios = axios.create({
});

appAxios.interceptors.request.use(
  (config) => {
    try {
      let decode = extractDecode();
      if (decode) {
        const obj: IInfoToken = JSON.parse(atob(decode));
        config.headers["Authorization"] = `Bearer ${obj?.accessToken}`;
      }
    } catch (error) {
      console.log(error);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

appAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (error?.response?.status === 504 || error?.response?.status === 500 || error?.response?.status === 0) return "Erro de comunicação.";
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      if (!isRefreshing) {
        isRefreshing = true;
        let decode = extractDecode();
        if (decode) {
          const obj: IInfoToken = JSON?.parse(atob(decode));

          if (!obj) throwErrorAndClearLocalStorage("OBJ inexistente");

          const refreshToken = obj.refreshToken;
          LoginService.renew({ refreshToken }).then((response) => {
            if (response instanceof ApiException) {
              throwErrorAndClearLocalStorage(response)
            } else {
              const tokenBody: IInfoToken = response;
              LocalStorageMethods.addLocalStorage(JSON.stringify(tokenBody), NAMES.INFO_TOKEN)
              // Define novamente o header de autorização nas requisições
              appAxios.defaults.headers["Authorization"] = `Bearer ${tokenBody.accessToken}`;

              // Faz todas as requisições que estavam na fila e falharam
              failedRequestQueue.forEach((request: any) =>
                request.onSuccess(tokenBody.accessToken)
              );
              // Limpa a fila de requisições que falharam
              failedRequestQueue = [];
            }
          }).catch((error) => {
            // Retorna os erros que estão salvos na fila de requisições que falharam
            failedRequestQueue.forEach((request: any) => request.onFailure(error));
            // Limpa a fila de requisições que falharam
            failedRequestQueue = [];

            // Caso der erro desloga o usuário
            throwErrorAndClearLocalStorage(error)
          }).finally(() => {
            isRefreshing = false;
          })
        }
      }

      // Usando a Promise no lugar do async await, para que a requisição seja feita após o refresh token
      return new Promise((resolve, reject) => {
        // Adiciona a requisição na fila de requisições que falharam com as informações necessárias para refazer a requisição novamente
        failedRequestQueue.push({
          // Se a requisição der sucesso, chama o onSuccess
          onSuccess: (token: string) => {
            // Adiciona o novo token gerado no refresh token no header de autorização
            originalConfig.headers["Authorization"] = `Bearer ${token}`;

            // Faz a requisição novamente passando as informações originais da requisição que falhou
            resolve(appAxios(originalConfig));
          },
          // Se a requisição der erro, chama o onFailure
          onFailure: (err: any) => {
            // Se não for possivel refazer a requisição, retorna o erro
            reject(err);
          },
        });
      });
    }
    if (error?.response?.data?.hasOwnProperty("message")) {
      return error?.response?.data?.message;
    }
    return error?.response?.data || error?.response?.data?.message || error?.message;
  }
);

export default appAxios;
