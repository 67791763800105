// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalReceivedPaymentSlip_container__twH\\+0 {
    flex-direction: column;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH {
    gap: .5em;
}

.ModalReceivedPaymentSlip_container__twH\\+0,
.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH input[type=button],
.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH button {
    margin-top: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 16px;
    height: 48px;
    width: 100px;
    padding: 14px, 20px, 14px, 20px;
    border-radius: 8px;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH .ModalReceivedPaymentSlip_btnNo__ucICN {
    background-color: #FFFFFF;
    border: 1px solid #E3E4E8;
    font-weight: 400;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH .ModalReceivedPaymentSlip_btnYes__SHyWA {
    background-color: #006930;
    color: #FFFFFF;
    font-weight: 750;

}`, "",{"version":3,"sources":["webpack://./src/features/paymentsSlip/modalReceivedPaymentSlip/ModalReceivedPaymentSlip.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;AACb;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;;IAEI,eAAe;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;;AAEpB","sourcesContent":[".container {\n    flex-direction: column;\n}\n\n.container .actions {\n    gap: .5em;\n}\n\n.container,\n.container .actions {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.container .actions input[type=button],\n.container .actions button {\n    margin-top: 1em;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    padding: 16px;\n    height: 48px;\n    width: 100px;\n    padding: 14px, 20px, 14px, 20px;\n    border-radius: 8px;\n}\n\n.container .actions .btnNo {\n    background-color: #FFFFFF;\n    border: 1px solid #E3E4E8;\n    font-weight: 400;\n}\n\n.container .actions .btnYes {\n    background-color: #006930;\n    color: #FFFFFF;\n    font-weight: 750;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalReceivedPaymentSlip_container__twH+0`,
	"actions": `ModalReceivedPaymentSlip_actions__+HuQH`,
	"btnNo": `ModalReceivedPaymentSlip_btnNo__ucICN`,
	"btnYes": `ModalReceivedPaymentSlip_btnYes__SHyWA`
};
export default ___CSS_LOADER_EXPORT___;
