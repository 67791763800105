// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_container__XGPum {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1em;

}

.Error_container__XGPum svg {
    width: 50px;
    height: 50px;
    color: #ffcc00;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 {
    gap: .7em;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 button {

    background-color: #006930;
    color: #FEFEFE;
    font-size: 16px;
    font-weight: 500;
    width: 47%;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 .Error_warning__2\\+jnK {
    color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/components/error/Error.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,QAAQ;;AAEZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n    gap: 1em;\n\n}\n\n.container svg {\n    width: 50px;\n    height: 50px;\n    color: #ffcc00;\n}\n\n.container .containerMessage {\n    gap: .7em;\n    font-size: 12px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    align-items: center;\n}\n\n.container .containerMessage button {\n\n    background-color: #006930;\n    color: #FEFEFE;\n    font-size: 16px;\n    font-weight: 500;\n    width: 47%;\n}\n\n.container .containerMessage .warning {\n    color: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__XGPum`,
	"containerMessage": `Error_containerMessage__+4o97`,
	"warning": `Error_warning__2+jnK`
};
export default ___CSS_LOADER_EXPORT___;
