// react
import { InputHTMLAttributes } from 'react';

//css
import styles from './Input.module.css';

type IInputProps = InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<IInputProps> = ({ ...rest }) => {
    return (
        <div className={styles.inputBox}>
            <input className={styles.container} {...rest} />
        </div>
    )
}

export default Input;