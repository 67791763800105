import styles from './Footer.module.css';

const Footer = ({ fixed = '' }: any) => {

    const getCurrentYear = (): string => {
        const currentDate = new Date();
        return currentDate?.getFullYear()?.toString();;
    }
    return (
        <footer className={`${styles.footer} ${styles[fixed]}`}>
            <p>© {getCurrentYear()} BBC Digital. Todos os direitos são reservados</p>
        </footer>
    )
}

export default Footer