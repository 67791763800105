//styles
import { BiError } from 'react-icons/bi';
import styles from './ErrorSuccess.module.css';

type Props = {
    message: string
    showCallCenter: boolean
}
const ErrorSuccess: React.FC<Props> = ({ message, showCallCenter = false }) => {
    return (
        <div className={styles.container}>
            <div className={styles.containerMessage}>
                <div>
                    <BiError />
                    <p> {message}</p>
                    {showCallCenter && (
                        <p>Entre em contato com o nosso atendimento:</p>
                    )}
                </div>
                {showCallCenter && (
                    <h3>Central de Atendimento 0800 771 4080</h3>
                )}
            </div>
        </div>
    )
}

export default ErrorSuccess