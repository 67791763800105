//exception
import { ApiException } from "../../api/ApiException";
//appAxios
import appAxios from "../../api/appAxios";

//interfaces
import { IdataRequest } from "../../interfaces/IdataRequest";
import { IInfoToken } from "../../interfaces/IInfoToken";
import { IInitDataRequest } from "../../interfaces/IInitDataRequest";
import { IOtpDataRequest } from "../../interfaces/IOtpDataRequest";
import { IRegisterDataRequest } from "../../interfaces/IRegisterDataRequest";
import { IRenewRequest } from "../../interfaces/IRenewRequest";

//const { createProxyMiddleware } = require('http-proxy-middleware');

const receiveToken = async (dataRequest: IdataRequest): Promise<any | ApiException> => {
    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_LOGIN}`, dataRequest);
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}

const initData = async (dataRequest: IInitDataRequest): Promise<any | ApiException> => {

    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_FIRST_ACCESS}`, dataRequest);
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}

const sendToken = async (dataRequest: IOtpDataRequest, isAuth = false): Promise<IInfoToken | ApiException> => {
    try {
        let url = `${process.env.REACT_APP_ROUTE_API_LOGIN_VALIDATE}`;

        if (isAuth)
            url = `${process.env.REACT_APP_ROUTE_API_AUTH_VALIDATE}`;


        const response = await appAxios.post(url, dataRequest);
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        console.log(error);
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}

const passwordRecovery = async (cpfCnpj: string) => {
    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_PASSWORD_RECOVERY}`,
            {
                document: cpfCnpj
            });
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        console.log(error);
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}


const register = async (dataRequest: IRegisterDataRequest) => {
    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_REGISTER}`, dataRequest);
        if (response?.status === 200) return;
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        console.log(error);
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}

const renew = async (refreshToken: IRenewRequest): Promise<IInfoToken | ApiException> => {
    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_REFRESH_TOKEN}`, refreshToken);
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        console.log(error);
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}
const resendOtp = async (identifier: string): Promise<void | ApiException> => {
    try {
        const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_RESEND_OTP}`, { identifier });
        const data = response?.data || new ApiException(String(response));
        return data;

    } catch (error: any) {
        console.log(error);
        return new ApiException(error?.response?.data?.message || error?.message);
    }
}

export const LoginService = {
    receiveToken,
    initData,
    sendToken,
    passwordRecovery,
    register,
    renew,
    resendOtp
}