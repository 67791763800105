//components
import Message from "../../components/message/Message";

//css
import styles from "./Login.module.css";

//images
import Logo from "../../images/logo.svg";
import SmallLogo from "../../images/thumbnail_BBC_DIGITAL-LOGO_original-1 2.svg";
import icon from '../../images/u_info-circle.svg';

//uttils
import { Formatters } from "../../utils/Formatters";

//react
import { useState, useRef, useEffect, useContext } from "react";
import { LoginService } from "../../services/login/LoginService";

// context
import { AuthContext } from "../../hooks/HookUseContext";

//exception
import { ApiException } from "../../api/ApiException";
//utils
import { LocalStorageMethods } from '../../utils/LocalStorageMethods';

//interfaces
import { IInfoToken } from '../../interfaces/IInfoToken';

//consts
import { NAMES } from '../../consts/namesLocalStorage';

import CryptoJS from 'crypto-js';


// login page states
import SeeLeftInformation from './SeeLeftInformation';
import SeeLoginScreen from "./SeeLoginScreen";
import SeeTokenScreen from "./SeeTokenScreen";
import SeeFirstAccessScreen from "./SeeFirstAccessScreen";
import SeePasswordRecoveryScreen from "./SeePasswordRecoveryScreen";
import SeePasswordFormScreen from "./SeePasswordFormScreen";
import SeePasswordFormConcludedScreen from "./SeePasswordFormConcludedScreen";

const Index: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [cpfCnpj, setCpfCnpj] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [token, setToken] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const { setAuthValue } = useContext(AuthContext);

  const [seeLoginScreen, setSeeLoginScreen] = useState<boolean>(true);
  const [seeTokenScreen, setSeeTokenScreen] = useState<boolean>(false);
  const [seePasswordFormScreen, setSeePasswordFormScreen] = useState<boolean>(false);
  const [seePasswordRecoveryScreen, setSeePasswordRecoveryScreen] = useState<boolean>(false);
  const [seeFisrtAccessScreen, setSeeFisrtAccessScreen] = useState<boolean>(false);
  const [seePasswordFormConcludedScreen, setSeePasswordFormConcludedScreen] = useState<boolean>(false);

  const [attemps, setAttemps] = useState<number>(0)

  const [passwordLabel, setPasswordLabel] = useState<string>("Senha");
  const [confirmPasswordLabel, setConfirmPasswordLabel] = useState<string>("Confirmar Senha");
  const [title, setTittle] = useState<string>("Registro");

  const passwordId = useRef(null);
  const key: any = process.env.REACT_APP_APY_KEY_CRYPTR;


  useEffect(() => {
    const el = passwordId.current;
    console.log(el);
  }, []);

  const cleanFields = (cpfCnpj: boolean = false) => {
    if (!cpfCnpj)
      setCpfCnpj('');

    setPassword('');
    setConfirmPassword('');
    setPhone('');
    setToken('');
    setMessage('');
  }

  const handleBackLogin = (e: React.FormEvent) => {
    cleanFields();
    setSeeLoginScreen(true);
    setSeeTokenScreen(false);
    setSeePasswordFormScreen(false);
    setSeePasswordRecoveryScreen(false);
    setSeeFisrtAccessScreen(false);
    setSeePasswordFormConcludedScreen(false);
  }

  const handleFisrtAccess = (e: React.FormEvent) => {
    cleanFields();
    setSeeLoginScreen(false);
    setSeeFisrtAccessScreen(true);
    setPhone("");
  }

  const handlePasswordRecovery = (e: React.FormEvent) => {
    cleanFields();
    setSeeLoginScreen(false);
    setSeePasswordRecoveryScreen(true);
  }

  const handleSubmitPasswordForm = async (e: React.FormEvent, captcha: any) => {
    e.preventDefault();
    setLoading(true);
    setMessage('')
    await LoginService.register({
      password: CryptoJS.AES.encrypt(password, key).toString(), confirmPassword: CryptoJS.AES.encrypt(confirmPassword, key).toString()
    }).then(
      (response) => {
        if (response instanceof ApiException) {
          setMessage(response.message);
          setAttemps(attemps + 1);
          if (captcha) captcha?.reset();
        } else {
          cleanFields();
          setSeePasswordFormScreen(false);
          setSeePasswordFormConcludedScreen(true);
        }
        setLoading(false);
      }
    );
  }

  const handleSubmitLogin = async (e: React.FormEvent, captcha: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    await LoginService.receiveToken({ username, password: CryptoJS.AES.encrypt(password, key).toString() }).then(
      (response) => {
        if (response instanceof ApiException) {
          setMessage(response.message);
          setSeeLoginScreen(true);
          setSeeTokenScreen(false);
          setAttemps(attemps + 1);
          if (captcha) captcha?.reset();
        } else {
          cleanFields();
          setIdentifier(response?.identifier);
          setSeeLoginScreen(true);
          setSeeTokenScreen(true);
        }
        setLoading(false);
      }
    );
  };

  const handleSubmitFisrtAccess = async (e: React.FormEvent, captcha: any) => {
    e.preventDefault();
    setLoading(true);

    const result = cpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    setMessage('');
    await LoginService.initData({ "document": result, phone }).then(
      (response) => {
        if (response instanceof ApiException) {
          setMessage(response.message);
          setAttemps(attemps + 1);
          if (captcha) captcha?.reset();
        } else {
          setPasswordLabel("Senha");
          setConfirmPasswordLabel("Nova senha");
          setTittle("Registro");
          setIdentifier(response.identifier);
          setSeeTokenScreen(true);
          setSeeFisrtAccessScreen(true);
        }
        setLoading(false);
      }
    );

  };

  const handleSubmitPasswordRecovery = async (e: React.FormEvent, captcha: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    await LoginService.passwordRecovery(cpfCnpj).then((response) => {
      if (response instanceof ApiException) {
        setMessage(response.message);
        setAttemps(attemps + 1);
        if (captcha) captcha?.reset();
      } else {
        setPasswordLabel("Nova senha");
        setConfirmPasswordLabel("Confirmar Nova senha");
        setTittle("Recuperar senha");
        setSeeTokenScreen(true);
        setSeePasswordRecoveryScreen(true);
        setIdentifier(response.identifier)
      }
      setLoading(false);
    })

  };


  const handleSubmitToken = async (e: React.FormEvent, captcha: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    await LoginService.sendToken({ identifier: identifier, "otp": token }, !seeLoginScreen).then((response) => {
      if (response instanceof ApiException) {
        setMessage(response.message);
        setAttemps(attemps + 1);
        if (captcha) captcha?.reset();
      } else {
        if (response?.accessToken && !seeLoginScreen) {
          const tokenBody: IInfoToken = response;
          LocalStorageMethods.addLocalStorage(JSON.stringify(tokenBody), NAMES.INFO_TOKEN_REGISTER)
        }
        if (seeLoginScreen) {
          const tokenBody: IInfoToken = response;
          LocalStorageMethods.removeLocalStorage(NAMES.INFO_TOKEN_REGISTER);
          LocalStorageMethods.addLocalStorage(JSON.stringify(tokenBody), NAMES.INFO_TOKEN)
          setAuthValue(tokenBody);
        } else {
          setSeePasswordRecoveryScreen(false);
          setSeeFisrtAccessScreen(false);
          setSeePasswordFormScreen(true);
        }
      }
      setLoading(false);
    })

  };

  const seePassword = (id: string): string => {

    const element = document.getElementById(
      id,
    ) as HTMLInputElement | null;

    let currentType = "password"
    if (element?.type != null) {
      if (element?.type === "password") {
        element.type = "text";
        currentType = "text"
      } else {
        element.type = "password";
        currentType = "password"
      }
    }
    return currentType;
  }

  return (
    <div className={styles.containerIndex}>
      <div className={styles.container}>
        <div className={styles.header}></div>
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.left}>
              <SeeLeftInformation
                Logo={Logo}
                SmallLogo={SmallLogo}
              />
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.leftRes}>
              <SeeLeftInformation
                Logo={Logo}
                SmallLogo={SmallLogo}
              />
            </div>
            <div className={styles.right}>
              {!seeTokenScreen && seeLoginScreen && (
                <SeeLoginScreen
                  handleSubmitLogin={handleSubmitLogin}
                  loading={loading}
                  username={username}
                  setUsername={setUsername}
                  Formatters={Formatters}
                  password={password}
                  setPassword={setPassword}
                  handlePasswordRecovery={handlePasswordRecovery}
                  message={message}
                  Message={Message}
                  handleFisrtAccess={handleFisrtAccess}
                  attemps={attemps}
                  setLoading={setLoading}
                  seePassword={seePassword}
                  setMessage={setMessage}
                />
              )}
              {/*  */}
              {/*seeTokenScreen && <SendToken setSeeTokenScreen={setSeeTokenScreen} />*/}
              {(seeLoginScreen || seeFisrtAccessScreen || seePasswordRecoveryScreen) && seeTokenScreen && (
                <SeeTokenScreen
                  handleSubmitToken={handleSubmitToken}
                  icon={icon}
                  loading={loading}
                  token={token}
                  setToken={setToken}
                  message={message}
                  Message={Message}
                  handleBackLogin={handleBackLogin}
                  identifier={identifier}
                  attemps={attemps}
                  setLoading={setLoading}
                  setMessage={setMessage}
                  setAttemps={setAttemps}
                />
              )}
              {!seeTokenScreen && seeFisrtAccessScreen && (
                <SeeFirstAccessScreen
                  handleSubmitFisrtAccess={handleSubmitFisrtAccess}
                  loading={loading}
                  cpfCnpj={cpfCnpj}
                  setCpfCnpj={setCpfCnpj}
                  Formatters={Formatters}
                  phone={phone}
                  setPhone={setPhone}
                  message={message}
                  Message={Message}
                  handleBackLogin={handleBackLogin}
                  attemps={attemps}
                  setLoading={setLoading}
                  setMessage={setMessage}
                />
              )}
              {
                !seeTokenScreen && seePasswordRecoveryScreen && (
                  <SeePasswordRecoveryScreen
                    handleSubmitPasswordRecovery={handleSubmitPasswordRecovery}
                    loading={loading}
                    cpfCnpj={cpfCnpj}
                    setCpfCnpj={setCpfCnpj}
                    Formatters={Formatters}
                    message={message}
                    Message={Message}
                    handleBackLogin={handleBackLogin}
                    attemps={attemps}
                    setMessage={setMessage}
                    setLoading={setLoading}
                  />

                )
              }
              {
                seePasswordFormScreen && (
                  <SeePasswordFormScreen
                    handleSubmitPasswordForm={handleSubmitPasswordForm}
                    cpfCnpj={cpfCnpj}
                    setCpfCnpj={setCpfCnpj}
                    Formatters={Formatters}
                    loading={loading}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    message={message}
                    Message={Message}
                    seePassword={seePassword}
                    handleBackLogin={handleBackLogin}
                    passwordLabel={passwordLabel}
                    confirmPasswordLabel={confirmPasswordLabel}
                    title={title}
                    attemps={attemps}
                    setLoading={setLoading}
                    setMessage={setMessage}
                  />
                )}
              {
                seePasswordFormConcludedScreen && (
                  <SeePasswordFormConcludedScreen handleBackLogin={handleBackLogin} />
                )
              }
            </div >
          </div >
        </div >
      </div >
    </div >
  );
};

export default Index;