//exception
import { ApiException } from "../../api/ApiException";

//axios
import appAxios from "../../api/appAxios";

//interfaces
import { IProfileInfo } from "../../interfaces/IProfileInfo";
import { IDataRegistration } from "../../interfaces/IDataRegistration";
import { ICheckPhoneChange } from "../../interfaces/ICheckPhoneChange";
import { ICheckPhoneChangeRequest } from "../../interfaces/ICheckPhoneChangeRequest";
import { ICepInformations } from "../../interfaces/ICepInformations";
import { ICepRequest } from "../../interfaces/ICepRequest";

const dataIsUpToDate = async (): Promise<IProfileInfo | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_SUMMARY_INFORMATION}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const findRegistrationInformation = async (): Promise<IDataRegistration | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_REGISTRATION_INFORMATION}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}
const updateRegistrationInformation = async (dataRegistrationRequest: IDataRegistration | any): Promise<IDataRegistration | ApiException> => {
  try {
    const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_REGISTRATION_INFORMATION}`, dataRegistrationRequest);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}
const checkPhoneChange = async (phone: string): Promise<ICheckPhoneChange | ApiException> => {
  try {
    const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_CHECK_PHONE}`, {
      phone
    });
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const resendOtp = async (): Promise<void | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_CUSTOMER_RESEND_OTP}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

const postCepInformations = async (cepInformations: ICepRequest): Promise<ICepInformations | ApiException> => {
  try {
    const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_CUSTOMER_GET_CEP}`, cepInformations);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
}

export const ClientService = {
  dataIsUpToDate,
  findRegistrationInformation,
  updateRegistrationInformation,
  checkPhoneChange,
  resendOtp,
  postCepInformations
};
