// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContractCards_contracts__45owO {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 32px;
    margin-top: 2em;
}


@media (max-width: 370px) {
    .ContractCards_contracts__45owO {
        display: flex;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/features/contract/contractCards/ContractCards.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4DAA4D;IAC5D,SAAS;IACT,eAAe;AACnB;;;AAGA;IACI;QACI,aAAa;QACb,eAAe;IACnB;AACJ","sourcesContent":[".contracts {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n    gap: 32px;\n    margin-top: 2em;\n}\n\n\n@media (max-width: 370px) {\n    .contracts {\n        display: flex;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contracts": `ContractCards_contracts__45owO`
};
export default ___CSS_LOADER_EXPORT___;
