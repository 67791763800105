// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YearSelector_yearSelectorContainer__2Hv27 {
    display: flex;
    align-items: center;
    gap: .3em;
    flex-wrap: wrap;
}

.YearSelector_yearSelectorButton__EUKzM,
.YearSelector_yearSelectorButtonLeft__cQPZw,
.YearSelector_yearSelectorButtonRight__iFjeW {
    background-color: #006930;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    color: #FEFEFE;
    font-weight: 600;
}

.YearSelector_yearSelectorButton__EUKzM {
    background-color: #fff;
    border: 1px solid #ccc;
    color: black;
    font-weight: normal;
}

.YearSelector_yearSelectorButton__EUKzM:hover {
    opacity: .8;
}

.YearSelector_yearSelectorButton__EUKzM:disabled {
    cursor: not-allowed;
}

.YearSelector_selected__PAdEI {
    font-weight: bold;
}



@media (max-width: 387px) {

    .YearSelector_yearSelectorButton__EUKzM,
    .YearSelector_yearSelectorButtonLeft__cQPZw,
    .YearSelector_yearSelectorButtonRight__iFjeW {
        background-color: #006930;
        border-radius: 4px;
        padding: 2px 2px;
        cursor: pointer;
        color: #FEFEFE;
        font-weight: 600;
        font-size: 10px;
    }

    .YearSelector_yearSelectorButton__EUKzM {
        background-color: #fff;
        border: 1px solid #ccc;
        color: black;
        font-weight: normal;

    }
    .YearSelector_selected__PAdEI {
        font-weight: bold;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/yearSelector/YearSelector.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;;;IAGI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;;;AAIA;;IAEI;;;QAGI,yBAAyB;QACzB,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;QACf,cAAc;QACd,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,sBAAsB;QACtB,sBAAsB;QACtB,YAAY;QACZ,mBAAmB;;IAEvB;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".yearSelectorContainer {\n    display: flex;\n    align-items: center;\n    gap: .3em;\n    flex-wrap: wrap;\n}\n\n.yearSelectorButton,\n.yearSelectorButtonLeft,\n.yearSelectorButtonRight {\n    background-color: #006930;\n    border-radius: 4px;\n    padding: 5px 10px;\n    cursor: pointer;\n    color: #FEFEFE;\n    font-weight: 600;\n}\n\n.yearSelectorButton {\n    background-color: #fff;\n    border: 1px solid #ccc;\n    color: black;\n    font-weight: normal;\n}\n\n.yearSelectorButton:hover {\n    opacity: .8;\n}\n\n.yearSelectorButton:disabled {\n    cursor: not-allowed;\n}\n\n.selected {\n    font-weight: bold;\n}\n\n\n\n@media (max-width: 387px) {\n\n    .yearSelectorButton,\n    .yearSelectorButtonLeft,\n    .yearSelectorButtonRight {\n        background-color: #006930;\n        border-radius: 4px;\n        padding: 2px 2px;\n        cursor: pointer;\n        color: #FEFEFE;\n        font-weight: 600;\n        font-size: 10px;\n    }\n\n    .yearSelectorButton {\n        background-color: #fff;\n        border: 1px solid #ccc;\n        color: black;\n        font-weight: normal;\n\n    }\n    .selected {\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yearSelectorContainer": `YearSelector_yearSelectorContainer__2Hv27`,
	"yearSelectorButton": `YearSelector_yearSelectorButton__EUKzM`,
	"yearSelectorButtonLeft": `YearSelector_yearSelectorButtonLeft__cQPZw`,
	"yearSelectorButtonRight": `YearSelector_yearSelectorButtonRight__iFjeW`,
	"selected": `YearSelector_selected__PAdEI`
};
export default ___CSS_LOADER_EXPORT___;
