//react
import { useEffect } from 'react';

// css
import styles from './AnticipationTable.module.css';

//moment
import moment from 'moment';

//tooltip
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from 'react-tooltip';
import { IContent } from '../../../../interfaces/IPage';
import { Formatters } from '../../../../utils/Formatters';

type Props = {
    data: IContent[]
    selected: number[];
    setSelected: React.Dispatch<React.SetStateAction<number[]>>;
    selectedAll: boolean;
    setSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
}
const AnticipationTable: React.FC<Props> = ({
    data, selected, setSelected, selectedAll, setSelectedAll
}) => {
    const toggleSelectedAll = () => {
        setSelectedAll(!selectedAll);
        if (!selectedAll) {
            const unifiedDistinctArray = Array.from(new Set([...selected, ...data.map(dataValue => dataValue.number)]));
            setSelected(unifiedDistinctArray);
        } else {
            setSelected([]);
        }
    }

    const toggleSelected = (id: number) => {
        if (selected.includes(id)) {
            setSelected(selected.filter(item => item !== id));
            setSelectedAll(false);
        } else {
            setSelected([...selected, id]);
        }
    };

    const hasSelected = (id: number) => {
        return selected.includes(id);
    };

    return (
        <table className={styles.containerTable}>
            <thead>
                <tr>
                    <th className={styles.checkbox}>
                        <Tooltip id="tooltip-selected-all" />
                        <input
                            type="checkbox"
                            checked={selectedAll}
                            onChange={() => toggleSelectedAll()}
                            data-tooltip-id="tooltip-selected-all"
                            data-tooltip-content="Antecipar o pagamento de todas as parcelas"
                        />
                    </th>
                    <th className={styles.headerColumn}>Vencimento</th>
                    <th className={styles.headerColumn}>Parcela atual</th>
                    <th className={styles.headerColumn}>Valor do documento</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map((dataValue: IContent, index) => (
                    <tr key={index}>
                        <td className={styles.checkbox}>
                            <Tooltip id="tooltip-selected" />
                            <input
                                type="checkbox"
                                checked={dataValue.visible && hasSelected(dataValue.number)}
                                onChange={() => toggleSelected(dataValue.number)}
                                disabled={!dataValue.visible}
                                data-tooltip-id="tooltip-selected"
                                data-tooltip-content={dataValue.visible ? "Antecipar o pagamento" : dataValue?.advisoryContactMessage}
                            />
                        </td>
                        <td>
                            <span className={styles.titleTable}>{"Vencimento:"}</span>
                            {dataValue.dueDate ? moment(dataValue.dueDate).format("DD/MM/yyyy") : ''}
                        </td>
                        <td>
                            <span className={styles.titleTable}>{"Parcela atual:"}</span>
                            {`${dataValue?.number}/${dataValue?.totalNumber}`}
                        </td>
                        <td>
                            <span className={styles.titleTable}>{"Valor do documento:"}</span>
                            {Formatters.formatMoneyInRealBrazil(dataValue?.installmentValue)}
                        </td>
                    </tr >
                ))}
            </tbody>
        </table >
    )
}

export default AnticipationTable