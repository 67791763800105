//css
import styles from "./Login.module.css";

type Props = {
    Logo: string
    SmallLogo: string
}
const SeeLeftInformation: React.FC<Props> = ({ Logo, SmallLogo }) => {
    return (
        <>
            <img className={styles.logoImg} src={Logo} alt="logo BBC" />
            <div className={styles.box}>
                <label>
                    Tudo o que você precisa, sem precisar ligar, com <strong>rapidez e sem complicação!</strong>
                </label>
                <div className={styles.sub_box}>
                    <div className={styles.inter_box}>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Antecipação de parcelas</span>
                        </div>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Informe de Rendimentos</span>
                        </div>
                    </div>
                    <div className={styles.inter_box}>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Emissão de carnê</span>
                        </div>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Emissão de 2º via de contrato</span>
                        </div>
                    </div>
                    <div className={styles.inter_box}>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Quitação de contrato</span>
                        </div>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Atualização de cadastro</span>
                        </div>
                    </div>
                    <div className={styles.inter_box}>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Emissão de extrato</span>
                        </div>
                        <div className={styles.group_img_text}>
                            <img src={SmallLogo} alt="Digital logo BBC" />
                            <span>Solicitação de 2º via de boleto</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SeeLeftInformation